body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

.rentimmo-app {
  .es {
    margin-top: -46px;
  }
  table {
    border-collapse: collapse;
    margin: auto;
    margin-top: 1rem;
    min-width: 50%;
    border-spacing: 0;
    border: 1px solid black;
  }

  table tr td > * {
    max-height: 5rem;
    overflow: hidden;
  }

  td > .td-child {
    max-height: 200px;
    overflow: hidden;
  }

  table tr td,
  table tr th {
    border: 1px solid black;
    padding: 3px 10px 3px 3px;
    min-width: 60px;
  }

  .header-comment,
  .header-contacts_history {
    min-width: 200px;
  }

  .cell-tel > textarea {
    min-width: 100px;
    font-size: 22px;
  }

  .cell-vacances_locatives input {
    max-width: 70%;
  }
  .cell-vacances_locatives input:after {
    content: "%";
  }

  .cell-quartier,
  .cell-comment,
  .cell-loyers_estimes,
  .cell-travaux_estimes,
  .cell-contacts_history {
    min-width: 18ch;
  }

  .cell-image img {
    width: 140px;
    height: 100px;
    object-fit: cover;
    object-position: center center;
  }

  textarea {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #99d;
    width: 100%;
    padding: 5px;
  }

  .small-text {
    font-size: 12px;
  }

  table tr:nth-child(2n) {
    background-color: #c9c9c9;
  }
  table tr:nth-child(2n + 1) {
    background-color: #f3f3f3;
  }

  table tr.row-favorite:nth-child(2n) {
    background-color: #f9f9a3;
  }
  table tr.row-favorite:nth-child(2n + 1) {
    background-color: #ffd;
  }
  table tr.row-vendu {
    opacity: 0.3;
  }
  table tr.row-vendu:nth-child(2n) {
    background-color: #dff;
  }
  table tr.row-vendu:nth-child(2n + 1) {
    background-color: #a3f9f9;
  }

  table tr.row-rdv:nth-child(2n) {
    background-color: #dfd;
  }
  table tr.row-rdv:nth-child(2n + 1) {
    background-color: #b9f3b9;
  }

  .ad-detail {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
  }

  .ad-detail .multiline {
    white-space: pre-line;
    min-width: 600px;
    margin-right: 3rem;
    flex: 2;
  }

  .photos {
    vertical-align: top;
    display: grid;
    grid-template-columns: calc(50% - 25px) calc(50% - 25px);
    gap: 1rem;
    flex: 3;
  }

  .photo > img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
    background: gray;
  }

  .attachments {
    vertical-align: top;
    display: grid;
    grid-template-columns: calc(50% - 25px) calc(50% - 25px);
    gap: 1rem;
    flex: 3;
  }

  .attachment > img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
    background: gray;
  }

  label input {
    margin-left: 8px;
  }

  .error {
    background-color: #c00;
    color: white;
    font-weight: bold;
    padding: 10px;
  }

  .current-price {
    font-weight: bold;
  }
  .old-price {
    font-size: 0.9rem;
    text-decoration: line-through;
  }

  .header {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    font-weight: bold;
    border-right: 1px solid black;
    background: black !important;
    color: white;
    margin: 0;
  }

  .ad-table .header {
    position: sticky;
    top: 0;
  }

  table tr:last-child td {
    border-bottom: 0;
  }

  body {
    padding: 1rem 0.5rem;
  }

  .simple-cell-content {
    padding: 12px 12px 0 0;
    border: 1px solid black;
    min-height: 5rem;
    text-align: center;
    margin-bottom: 10px;
    height: calc(100% - 58px);
  }

  .simple-cell-textarea {
    height: 100%;
  }

  .simple-cell-content,
  .simple-cell-content textarea {
    font-size: 22px;
  }

  .simple-table {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(160px, 100%), 1fr));
    max-width: 100%;
    bottom: 0;
  }
  .splitted-table-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 40px;
  }

  .drag-active .non-drop-area {
    display: none;
  }
  .drop-area {
    display: none;
  }
  .drag-active {
    height: calc(100% - 258px);
    width: 100%;
  }
  .drag-active .drop-area {
    display: block;
    border: 4px dashed red;
    text-align: center;
    padding: 40vh 0;
    font-size: 45px;
    font-weight: bold;
    height: 100%;
    width: 100%;
  }

  .splitted-table {
    flex: 1;
  }

  .simple-table-contacts_history,
  .simple-table-comment,
  .simple-table-quartier,
  .simple-table-travaux_estimes,
  .simple-table-loyers_estimes {
    grid-column: span 2;
    grid-row: span 2;
  }

  .simple-table-contacts_history,
  .simple-table-comment {
    min-height: 280px;
  }

  .simple-table textarea {
    height: calc(100% - 14px);
  }

  .simple-cell-content.cell-renta {
    font-size: 35px;
    font-weight: bold;
  }

  .simple-header {
    text-align: center;
  }

  .notfav,
  .fav {
    font-size: 30px;
    display: inline-block;
    padding: 6px;
  }
  .notfav {
    color: #999;
  }

  .cell-favorite .cursor-pointer,
  .cell-favorite .btn-small {
    vertical-align: middle;
  }

  table tr textarea {
    min-height: 5rem;
  }
  .fav {
    color: #bb0;
  }

  .btn-group {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .btn-group button {
    margin-left: 0.5rem;
  }
  .link-group,
  .account-info {
    display: inline-block;
    margin-left: 0.5rem;
  }
  .link-group {
    margin-right: 0.5rem;
  }
  .link-group a {
    margin-left: 0.5rem;
  }

  button.selectable {
    border: 4px solid transparent;
  }
  button.selected {
    border: 4px solid black;
  }

  .screen-centered {
    width: 80%;
    margin: auto;
    vertical-align: middle;
    font-size: 60px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  .login input,
  .login button {
    height: 60px;
    min-width: 20ch;
  }
  .login input {
    width: 40ch;
  }
  button {
    border-radius: 12px;
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

  button.btn-small {
    border-radius: 6px;
    padding: 2px 2px;
    font-size: 0.8rem;
  }
  button.secondary {
    background-color: transparent;
    color: black;
    border: 2px solid #4caf50;
  }

  .cell-actions button {
    width: 130px;
  }

  .login span,
  .login input {
    vertical-align: bottom;
    margin-right: 2rem;
  }

  .cursor-pointer,
  button {
    cursor: pointer;
  }

  .simple-cell-content.cell-actions button {
    margin-bottom: 8px;
  }

  .img-placeholder {
    width: 140px;
    height: 60px;
    background-color: gray;
    text-align: center;
    padding-top: 40px;
  }

  .min-max-filter {
    min-width: 12ch;
  }
  .min-max-filter input {
    width: 100%;
  }

  .header input {
    min-width: 100%;
  }
}
